
import 'modaal';
import 'intersection-observer';
/*
import { throttle } from "lodash";
const FPS = 1000 / 60;
let resizeTimer = false;
*/

document.addEventListener('DOMContentLoaded', function(){

    // TODO: ページトップへ戻る
    /*
    $('#js-scroll-to-top').on('click', function () {
        $('html,body').animate({
            'scrollTop': 0
        }, 500);
    });
    */

    // TODO: ドロワーを開く
    $('#js-nav-sp__button--open').on('click', function () {
        $('#js-drawer__overlay').toggleClass('is-active');
        $('#js-drawer__button--close').toggleClass('is-active');
        $('#js-drawer__body').toggleClass('is-active');
    });

    // TODO: ドロワーを閉じる
    $('#js-drawer__overlay, #js-drawer__button--close').on('click', function () {
        $('#js-drawer__overlay').removeClass('is-active');
        $('#js-drawer__button--close').removeClass('is-active');
        $('#js-drawer__body').removeClass('is-active');
    });

    // TODO: カートドロワーの開閉
    /*
    $('#js-header__utility-cart').on('click', '#js-cart-trigger, #js-cart-cancel, #js-cart__overlay', function () {
        $('.js-cart-target').toggleClass('is-active');
    });
    */

    // TODO: ドロワー内のカテゴリを開閉
    /*
    $('.js-category-nav-sp__heading').on('click', function () {
        $(this).siblings('.js-category-nav-sp__list').slideToggle(200, 'linear');
        $(this).toggleClass('is-active');
    });
    */

    // TODO: イベント実行時のオーバーレイ処理
    // classに「load-overlay」が記述されていると画面がオーバーレイされる
    $('.js-load-overlay').on({
        click: function () {
            loadingOverlay();
        },
        change: function () {
            loadingOverlay();
        }
    });

    // TODO: modaalの呼び出し
    // ヘッダーの検索アイコンクリック時にモーダルが表示される
    /*
    $('#js-header__utility-search-link').modaal({
        custom_class: 'c-modaal',
        overlay_opacity: 0.5
    });
    */

    // TODO: IntersectionObserverでの監視
    /*
    const observerTargets = Array.prototype.slice.call(document.querySelectorAll('#js-top-app-bar, #js-top__main-visual, .js-animation-image, #js-recommend__contents'), 0);
    const observerOptions = [{
        root: null,
        rootMargin: "0px",
        threshold: 1.0
    },{
        root: null,
        rootMargin: "0px",
        threshold: [0, 1.0]
    },{
        root: null,
        rootMargin: "0px",
        threshold: 0.25
    }, {
        root: null,
        rootMargin: "0px",
        threshold: 0.5
    }];
    const topAppBarObserver = new IntersectionObserver(intersectState, observerOptions[0]);
    const mainVisualObserver = new IntersectionObserver(intersectTransparent, observerOptions[1]);
    const imageObserver = new IntersectionObserver(intersectShow, observerOptions[2]);
    const recommendObserver = new IntersectionObserver(intersectShow, observerOptions[3]);
    observerTargets.forEach(function(elem) {
        if(elem.getAttribute('id') == 'js-top-app-bar'){
            topAppBarObserver.observe(elem);
        } else if(elem.getAttribute('id') == 'js-top__main-visual'){
            mainVisualObserver.observe(elem);
        } else if(elem.classList.contains('js-animation-image') == true) {
            imageObserver.observe(elem);
        } else {
            recommendObserver.observe(elem);
        }
    });
    */

    // TODO: 3秒後にstopLoading呼び出し
    setTimeout(stopLoading, 3000);

},false);

window.addEventListener('load', function(){

	// TODO: リソース読み込み完了時にクラス付与
    $('#js-loading, #js-animation-cross').addClass('is-loaded');

    //scrollToTopFloating();
    //moveElem();

},false);

/*
window.addEventListener('scroll', throttle(function(){
    scrollToTopFloating();
}, FPS), isPassiveSupport() ? {
    passive: true
}: false);
*/

/*
window.addEventListener("resize", function(){
    if(resizeTimer){
        clearTimeout(resizeTimer);
    }
    resizeTimer = setTimeout(function(){
        moveElem();
        resizeTimer = false;
    }, 200);
}, isPassiveSupport() ? {
    passive: true
}: false);
*/

window.addEventListener("pageshow", function(){
    loadingOverlay('hide');
}, false);

/**
 *  オーバーレイ処理
 */
let loadingOverlay = function(action) {
    let $overlay;
    if (action == 'hide') {
        $('#js-overlay').remove();
    } else {
        $overlay = $('<div class="c-load-overlay" id="js-overlay"></div>');
        $('body').append($overlay);
    }
}
window.loadingOverlay = loadingOverlay;

/**
 *  top-app-barの状態
 */
/*
function intersectState(entry) {
    if (entry[0].isIntersecting) {
        entry[0].target.classList.remove('is-hide');
        entry[0].target.classList.add('is-show');
    } else {
        entry[0].target.classList.remove('is-show');
        entry[0].target.classList.add('is-hide');
        let categoryElem = document.getElementById('js-header__category');
        if(categoryElem.classList.contains('mdc-menu-surface--open') == true) {
            window.menuElements[0].open = false;
        }
    }
}
*/

/**
 *  headerの透過
 */
/*
function intersectTransparent(entry) {
    let headerElem = document.getElementById('js-top-app-bar');
    if (entry[0].isIntersecting) {
        headerElem.classList.add('is-transparent');
    } else {
        headerElem.classList.remove('is-transparent');
    }
}
*/

/**
 *  画像の表示アニメーション
 */
/*
function intersectShow(entries) {
    const entriesArray = Array.prototype.slice.call(entries, 0);
    entriesArray.forEach(function(entry) {
        if (entry.isIntersecting) {
            entry.target.classList.add("is-show");
        }
    });
}
*/

/**
 *  headerの配置要素替え
 */
/*
function moveElem() {
    let headerCat = $('#js-header__category'),
        drawer = $('#js-drawer__contents'),
        category = $('#js-category-nav-sp'),
        menu = $('#js-login');
    if (window.matchMedia('(max-width: 839px)').matches) {
        if(!drawer.has(category)[0]) {
            category.appendTo(drawer);
            menu.appendTo(drawer);
        }
    } else {
        if(!headerCat.has(category)[0]) {
            category.prependTo(headerCat);
            menu.prependTo('#js-header__utility');
        }
    }
}
*/

/**
 *  scroll to top
 */
/*
function scrollToTopFloating() {
    let target = $('#js-scroll-to-top');
    if ($(window).scrollTop() > 500) {
        target.addClass('is-show');
        target.removeClass('is-hide');
    } else {
        target.addClass('is-hide');
        target.removeClass('is-show');
    }
}
*/

/**
 *  loadingが完了しない場合
 */
function stopLoading(){
    $('#js-loading').addClass('is-loaded');
}

/**
 *  Passive Event Listeners polyfill
 */
/*
function isPassiveSupport(){
  if (typeof window === 'object' && typeof window.addEventListener === 'function' && Object.defineProperty) {
    let passive = false;
    const options = Object.defineProperty({}, 'passive', {
      get() {
          passive = true;
          return passive;
      }
    });
    window.addEventListener('test', null, options);
    return passive;
  }
}
*/
